import axios from 'axios'
import lSelector from 'jquery'
import Swal from 'sweetalert2'
import GeneralMethods from '../modules/GeneralMethods'
import * as Types from '../modules/ModuleType'
const Logo = require('../../assets/img/logo.gif')

type formData = {
    token:string
    employee_number:string
    session_code:number
    date_from:string
    date_to:string
    type:string
    limit:number
    offset:number
}

class DashboardBody {
    private _DashboardState:Types.dashboardState
    private _GM:GeneralMethods
    private _FormData:formData

    constructor() {
        this._DashboardState = {
            employee_number: '',
            full_name: '',
            dob: '',
            phone: '',
            email: '',
            unit: '',
            district: '',
            region: '',
            session_code: 0
        }
        this._GM = new GeneralMethods()
        this._setStateData()
        this._FormData = {
            token: '',
            employee_number: '',
            session_code: 0,
            date_from: '',
            date_to: '',
            type: '',
            limit: 10,
            offset: 0,
        }
    }

    public render() {
        const date = new Date()
        return (`
            <div class="container mb-5 pb-5">
                <div class="row mt-5 mb-3">
                    <div class="col-md-12">
                        <h4 class="text-dark"> Welcome, ${this._DashboardState.full_name} </h4>
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-md-12">
                        <div class="card p-3">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h6 class="text-muted"> Personal Details </h6>
                                        <hr class="m-0 p-0" />
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> Staff ID </label>
                                                <p class="text-dark"> ${this._DashboardState.employee_number.toString().toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> Name of Member </label>
                                                <p class="text-dark"> ${this._DashboardState.full_name.toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> Date of birth </label>
                                                <p class="text-dark"> ${(this._DashboardState.dob) ? this._GM.fullDate(this._DashboardState.dob) : ''} </p>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> Phone Number </label>
                                                <p class="text-dark"> ${this._DashboardState.phone} </p>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> Email Address </label>
                                                <p class="text-dark"> ${this._DashboardState.email.toUpperCase()} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <h6 class="text-muted"> Other Details </h6>
                                        <hr class="m-0 p-0" />
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> Management Unit </label>
                                                <p class="text-dark"> ${this._DashboardState.unit.toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> District </label>
                                                <p class="text-dark"> ${this._DashboardState.district.toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12">
                                                <label class="text-muted"> Region </label>
                                                <p class="text-dark"> ${this._DashboardState.region.toUpperCase()} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <h6 class="text-muted"> Contribution Summary </h6>
                                        <hr class="m-0 p-0" />
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 colo-12 mb-3">
                                                <label class="text-muted"> Years of Contribution <span style="font-size:small; color:#03830A;">(Deductions)</span>: </label>
                                                <span class="d-block text-dark stb_summary_total_years_of_contribution">
                                                    <div class="spinner-border text-muted" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12 mb-3">
                                                <label class="text-muted"> Total Number of Contributions <span style="font-size:small; color:#03830A;">(Deductions)</span>: </label>
                                                <span class="d-block text-dark stb_summary_number_of_contributions">
                                                    <div class="spinner-border text-muted" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12 mb-3">
                                                <label class="text-muted"> Total Contributions (TC) <span style="font-size:small; color:#03830A;">(Deductions)</span>: </label>
                                                <span class="d-block stb_summary_total_contributions">
                                                    <div class="spinner-border text-muted" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 col-12 mb-3">
                                                <label class="text-muted"> Total Debits (TD) <span style="font-size:small; color:#03830A;">(Deductions)</span>: </label>
                                                <span class="d-block text-warning stb_summary_total_debits">
                                                    <div class="spinner-border text-warning" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12 mb-3">
                                                <label class="text-muted"> Total Number of Share Benefits: </label>
                                                <span class="d-block stb_summary_share_benefits">
                                                    <div class="spinner-border text-muted" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12 mb-3">
                                                <label class="text-muted"> Total Interest Share Benefits (TISB): </label>
                                                <span class="d-block stb_summary_total_benefits">
                                                    <div class="spinner-border text-muted" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-4 col-sm-12 col-12 mb-3">
                                                <label class="text-muted"> Total Withdrawal (TW): </label>
                                                <span class="d-block stb_summary_total_withdrawal text-danger">
                                                    <div class="spinner-border text-danger" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                            <div class="col-md-4 col-sm-12 col-12 mb-3">
                                                <label class="text-muted"> Total Balance (TC - TD + TISB - TW): </label>
                                                <span class="d-block stb_summary_total_balance text-primary">
                                                    <div class="spinner-border text-primary" role="status"> <span class="visually-hidden">Loading...</span> </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <h6 class="text-muted"> Contribution History Report </h6>
                                        <hr class="m-0 p-0" />
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <form class="stb_contribution_report_submit" method="POST">
                                            <div class="row">
                                                <div class="col-md-3 col-sm-6 col-6 mt-2">
                                                    <label class="text-muted"> Year From </label>
                                                    <input type="number" class="form-control stb_contribution_report_from" min="2009" max="${date.getUTCFullYear()}" step="1" value="${date.getUTCFullYear()}" placeholder="Year From" required>
                                                </div>
                                                <div class="col-md-3 col-sm-6 col-6 mt-2">
                                                    <label class="text-muted"> Year To </label>
                                                    <input type="number" class="form-control stb_contribution_report_to" min="2009" max="${date.getUTCFullYear()}" step="1" value="${date.getUTCFullYear()}" placeholder="Year To" required>
                                                </div>
                                                <div class="col-md-3 col-sm-6 col-6 mt-2">
                                                    <label class="text-muted"> Type </label>
                                                    <select class="form-control stb_contribution_report_type">
                                                        <option value="all" selected="">All</option>
                                                        <option value="deduction statement">Deduction Statement</option>
                                                        <option value="interest share statement">Interest Share Statement</option>
                                                    </select>
                                                </div>
                                                <div class="col-md-3 col-sm-6 col-6 mt-2">
                                                    <button type="submit" class="btn btn-primary stb_contribution_report_submit_btn" style="margin-top: 1.4em;width:100%;height:2.9rem;">
                                                        <i class="bi bi-clipboard-data mr-3"></i> Fetch
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card p-2 mt-3 hide stb_contribution_report_card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-12 text-right" style="justify-content: right; text-align: right;">
                                        <button type="button" class="btn mr-2 text-white stb_contribution_report_close_btn" style="background-color: #03830A61;"> <i class="bi bi-x-circle-fill"></i> Close </button>
                                        <button type="button" class="btn bg-primary mr-2 text-white stb_contribution_report_print_btn"> <i class="bi bi-printer-fill"></i> Print</button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body" id="stb_contribution_report_card_print_body">
                                <!-- DISPLAY ON MOBILE -->
                                <div class="row d-lg-none">
                                    <div class="col-12 text-center" style="justify-content: center; text-align: center;">
                                        <img class="" src="${Logo}" alt="logo"/>
                                    </div>
                                    <div class="col-md-12 text-center" style="justify-content: center; text-align: center;">
                                        <p class="text-primary pb-0 mb-0">
                                            FORESTRY COMMISSION || STB SCHEME MANAGEMENT PORTAL
                                        </p>
                                        <p class="text-muted m-0 p-1">P. O. Box MB 434 Accra-Ghana</p>
                                        <p class="text-muted m-0 p-1">info.hq@fcghana.org</p>
                                        <p class="text-muted m-0 p-1">0302401227 | 0302401210</p>
                                    </div>
                                    <div class="col-12 mt-5 text-center">
                                        <h6 class="text-dark"> CONTRIBUTION HISTORY REPORT </h6>
                                    </div>
                                </div>

                                <!-- DISPLAY ON DESKTOP -->
                                <div class="row d-none d-lg-flex">
                                    <div class="col-md-8 text-left">
                                        <h3 class="text-dark"> CONTRIBUTION HISTORY REPORT </h3>
                                    </div>
                                    <div class="col-md-4 text-right" style="justify-content: right; text-align: right;">
                                        <img class="" src="${Logo}" alt="logo"/>
                                    </div>
                                </div>
                                <div class="row d-none d-lg-flex">
                                    <div class="col-md-4">
                                        <p class="text-primary pb-0 mb-0"> REPORT DETAILS </p>
                                        <p class="text-muted m-0 p-1"> From: <span class="stb_contribution_report_span_from">2021</span> </p>
                                        <p class="text-muted m-0 p-1"> To: <span class="stb_contribution_report_span_to">2021</span> </p>
                                        <p class="text-muted m-0 p-1"> Total Records: <span class="stb_contribution_report_records"></span></p>
                                        <label class="text-muted bold"> <b>Grand Total</b>: <span class="text-warning stb_contribution_report_grandtotal">0.00</span> </label>
                                    </div>
                                    <div class="col-md-8 text-right" style="justify-content: right; text-align: right;">
                                        <p class="text-primary pb-0 mb-0">
                                            FORESTRY COMMISSION || STB SCHEME MANAGEMENT PORTAL
                                        </p>
                                        <p class="text-muted m-0 p-1">P. O. Box MB 434 Accra-Ghana</p>
                                        <p class="text-muted m-0 p-1">info.hq@fcghana.org</p>
                                        <p class="text-muted m-0 p-1">0302401227 | 0302401210</p>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-md-12">
                                        <h6 class="text-primary"> Member Details </h6>
                                        <hr class="m-0 p-0" style="border: 2px solid #03830A;" />
                                    </div>
                                    <div class="col-md-12 mt-2">
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 col-12">
                                                <label class="text-muted" style="font-size:smaller;"> Staff ID </label>
                                                <p class="text-dark" style="font-size:smaller;"> ${this._DashboardState.employee_number.toString().toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12">
                                                <label class="text-muted" style="font-size:smaller;"> Name of Member </label>
                                                <p class="text-dark" style="font-size:smaller;"> ${this._DashboardState.full_name.toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12">
                                                <label class="text-muted" style="font-size:smaller;"> Phone Number </label>
                                                <p class="text-dark" style="font-size:smaller;"> ${this._DashboardState.phone} </p>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12">
                                                <label class="text-muted" style="font-size:smaller;"> Email Address </label>
                                                <p class="text-dark" style="font-size:smaller;"> ${this._DashboardState.email.toUpperCase()} </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3 col-sm-12 col-12">
                                                <label class="text-muted" style="font-size:smaller;"> Management Unit </label>
                                                <p class="text-dark" style="font-size:smaller;"> ${this._DashboardState.unit.toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12">
                                                <label class="text-muted" style="font-size:smaller;"> District </label>
                                                <p class="text-dark" style="font-size:smaller;"> ${this._DashboardState.district.toUpperCase()} </p>
                                            </div>
                                            <div class="col-md-3 col-sm-12 col-12">
                                                <label class="text-muted" style="font-size:smaller;"> Region </label>
                                                <p class="text-dark" style="font-size:smaller;"> ${this._DashboardState.region.toUpperCase()} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- DISPLAY ON MOBILE -->
                                <div class="row d-lg-none">
                                    <div class="col-md-4">
                                        <p class="text-primary pb-0 mb-0"> REPORT DETAILS </p>
                                        <p class="text-muted m-0 p-1"> From: <span class="stb_contribution_report_span_from">2021</span> </p>
                                        <p class="text-muted m-0 p-1"> To: <span class="stb_contribution_report_span_to">2021</span> </p>
                                        <p class="text-muted m-0 p-1"> Total Records: <span class="stb_contribution_report_records"></span></p>
                                        <label class="text-muted bold"> <b>Grand Total</b>: <span class="text-warning stb_contribution_report_grandtotal">0.00</span> </label>
                                    </div>
                                </div>

                                <div class="row mt-5">
                                    <div class="col-md-12 table-responsive stb_contribution_report_table_display"></div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-4 d-flex text-left">
                                        <select class="form-control mr-2 ovasyte_report_offset_number_dropdown" style="width:100px">
                                            <option value="10" selected> 10 </option>
                                            <option value="20"> 20 </option>
                                            <option value="50"> 50 </option>
                                            <option value="100"> 100 </option>
                                            <option value="150"> 150 </option>
                                            <option value="200"> 200 </option>
                                            <option value="250"> 250 </option>
                                        </select>
                                        <button type="button" class="btn bg-primary text-white ovasyte_report_offset_number_btn">Get 10 More Records <i class="far fa-arrow-alt-circle-right"></i></button>
                                    </div>
                                    <div class="col-md-8 text-right" style="justify-content: right; text-align: right;">
                                        <button type="button" class="btn mr-2 text-white stb_contribution_report_close_btn" style="background-color: #03830A61;"> <i class="bi bi-x-circle-fill"></i> Close </button>
                                        <button type="button" class="btn bg-primary mr-2 text-white stb_contribution_report_print_btn"> <i class="bi bi-printer-fill"></i> Print</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-bottom:20vh"></div>
        `)
    }

    private _setStateData() {
        const data = window.localStorage.getItem('mystbstate')
        if (data && data != undefined) {
            this._DashboardState = JSON.parse(data)
        }
    }

    public methods() {
        lSelector(document).off('submit.formSubmit')
        lSelector(document).off('click.otherclicks')

        this._fetchSummary()
        this._contributionReportSubmit()
        this._buttonActions()
    }

    private _fetchSummary() {
        axios({
            method: 'POST',
            url: this._GM.apiUrl()+'/contribution/summary',
            data: {
                token: '9a741c670d094ba6cf040e12b525bcd7',
                employee_number: this._DashboardState.employee_number,
                session_code: this._DashboardState.session_code
            }
        }).then((res) => {
            const data:Types.summary = res.data
            lSelector('.stb_summary_total_years_of_contribution').html(`${data.years_of_contribution < 1 ? 'Less than a year' : data.years_of_contribution}`)
            lSelector('.stb_summary_number_of_contributions').html(`${data.number_of_contributions}`)
            lSelector('.stb_summary_total_contributions').html(`${this._GM.formatNumber(Number(data.total_contributions) - Number(data.total_income))}`)
            lSelector('.stb_summary_total_debits').html(`${this._GM.formatNumber(Number(data.total_debit))}`)
            lSelector('.stb_summary_share_benefits').html(`${Number(data.share_benefits) + Number(data.number_of_income)}`)
            lSelector('.stb_summary_total_benefits').html(`${this._GM.formatNumber(Number(data.total_benefits) + Number(data.total_income))}`)
            lSelector('.stb_summary_total_withdrawal').html(`${this._GM.formatNumber(Number(data.total_withdrawal))}`)
            lSelector('.stb_summary_total_balance').html(`${this._GM.formatNumber(((Number(data.total_contributions) - Number(data.total_debit)) + Number(data.total_benefits)) - Number(data.total_withdrawal))}`)
        }).catch((error) => {
            console.log('error: ', error)
        }) 
    }

    private _contributionReportSubmit() {
        const thisClass = this
        lSelector(document).on('submit.formSubmit', 'form.stb_contribution_report_submit', function(e) {
            e.preventDefault()

            lSelector('.stb_contribution_report_submit_btn').html(`<div class="spinner-border text-light" role="status"> <span class="visually-hidden">Loading...</span> </div>`)
            lSelector('.stb_contribution_report_submit_btn').attr('disabled', 'disabled')

            const stb_contribution_report_from:any = lSelector('.stb_contribution_report_from', this).val()
            const stb_contribution_report_to:any = lSelector('.stb_contribution_report_to', this).val()
            const stb_contribution_report_type:any = lSelector('.stb_contribution_report_type', this).val()

            thisClass._sendData({
                token: '9a741c670d094ba6cf040e12b525bcd7',
                employee_number: thisClass._DashboardState.employee_number,
                session_code: thisClass._DashboardState.session_code,
                date_from: stb_contribution_report_from,
                date_to: stb_contribution_report_to,
                type: stb_contribution_report_type,
                limit: 10,
                offset: 0
            })
        })
    }

    private _deductionTableDraw(data:any, checker:string, offset:number):Object {
        let htmlTable = ''
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                // <td style="font-size:12px;">${this._GM.formatNumber(Number(item.balance))}</td>
                htmlTable += `
                    <tr>
                        <td style="font-size:12px;">${(offset + i) + 1}</td>
                        <td style="font-size:12px;">${item.month.split("-")[0]}</td>
                        <td style="font-size:12px;">${this._GM.getMonthString(item.month.split("-")[1])}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber(Number(item.debit))}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber(Number(item.deduction))}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber((Number(item.balance) + (Number(item.deduction) - Number(item.debit))))}</td>
                        <td style="font-size:12px;">${this._GM.fullDate(item.date_time)}</td>
                    </tr>
                `
            }
        } else {
            if (checker == 'new') {
                htmlTable += `<tr> <td colspan=8"> No data found. </td></tr>`
            }
        }
    
        return htmlTable
    }
    
    private _shareInterestTableDraw(data:any, checker:string, offset:number):Object {
        let htmlTable = ''
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i]
                htmlTable += `
                    <tr>
                        <td style="font-size:12px;">${(offset + i) + 1}</td>
                        <td style="font-size:12px;">${item.year}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber(Number(item.balance))}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber(Number(item.amount))}</td>
                        <td style="font-size:12px;">${this._GM.fullDate(item.uploaded_date)}</td>
                    </tr>
                `
            }
        } else {
            if (checker == 'new') {
                htmlTable += `<tr> <td colspan=5"> No data found. </td></tr>`
            }
        }
    
        return htmlTable
    }
    
    private _allTableDraw(data:any, checker:string, offset:number):Object {
        let htmlTable = ''
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                const item = data[i]
                // <td style="font-size:12px;">${this._GM.formatNumber(Number(item.balance))}</td>
                htmlTable += `
                    <tr>
                        <td style="font-size:12px;">${(offset + i) + 1}</td>
                        <td style="font-size:12px;">${item.year}</td>
                        <td style="font-size:12px;">${item.month}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber(Number(item.debit))}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber(Number(item.amount))}</td>
                        <td style="font-size:12px;">${this._GM.formatNumber((Number(item.balance) + (Number(item.amount) - Number(item.debit))))}</td>
                        <td style="font-size:12px;">${item.description}</td>
                        <td style="font-size:12px;">${this._GM.fullDate(item.uploaded_date)}</td>
                    </tr>
                `
            }
        } else {
            if (checker == 'new') {
                htmlTable += `<tr> <td colspan="9"> No data found. </td></tr>`
            }
        }
    
        return htmlTable
    }

    private _buttonActions() {
        const thisClass = this
        lSelector(document).off('click.otherclicks')

        lSelector(document).on('click.otherclicks', 'button.stb_contribution_report_close_btn', function(e) {
            e.preventDefault()
            lSelector('.stb_contribution_report_card').addClass('hide')
        })

        lSelector(document).on('click.otherclicks', 'button.stb_contribution_report_print_btn', function(e) {
            e.preventDefault()
            thisClass._GM.print('stb_contribution_report_card_print_body')
        })
        
        lSelector(document).off('change.ovasyte_report_offset_number_dropdown')
        lSelector(document).on('change.ovasyte_report_offset_number_dropdown', 'select.ovasyte_report_offset_number_dropdown', function() {
            let value = lSelector(this).val()
            lSelector('.ovasyte_report_offset_number_btn').html(`Get ${value} More Records <i class="far fa-arrow-alt-circle-right"></i>`)
        })

        lSelector(document).off('click.ovasyte_report_offset_number_btn')
        lSelector(document).on('click.ovasyte_report_offset_number_btn', 'button.ovasyte_report_offset_number_btn', function(e) {
            e.preventDefault()
            let limit = lSelector('.ovasyte_report_offset_number_dropdown').val()
            
            lSelector(this).html('<div class="mr-2 spinner-border align-self-center loader-sm"></div>');
            lSelector(this).attr('disabled', 'disabled');

            thisClass._sendData({
                token: thisClass._FormData.token,
                employee_number: thisClass._FormData.employee_number,
                session_code: thisClass._FormData.session_code,
                date_from: thisClass._FormData.date_from,
                date_to: thisClass._FormData.date_to,
                type: thisClass._FormData.type,
                limit: Number(limit),
                offset: (Number(thisClass._FormData.limit) + Number(thisClass._FormData.offset)),
            })
        })
    }

    private _sendData(formData:formData) {
        this._FormData = formData
        axios({
            method: 'POST',
            url: this._GM.apiUrl()+'/contribution/report',
            data: formData
        }).then((res) => {
            const data:any = res.data
            if (data && data.status == 'error' || data && data.status == 'caution') {
                Swal.fire(
                    (data.status == 'caution' ? 'Caution' : 'Error'),
                    data.message,
                    (data.status == 'caution' ? 'warning' : 'error')
                )
                lSelector('.swal2-confirm').removeClass('swal2-styled')
                lSelector('.swal2-confirm').addClass('btn btn-primary')
            } else {
                let result:any
                if (formData.type == 'deduction statement') {
                    result = this._deductionTableDraw(data.data, (formData.offset == 0 ? 'new' : 'append'), formData.offset)
                    if (formData.offset == 0) {
                        // <th style="font-size:12px;" class="text-white"> Bal. B/FWRD </th>
                        lSelector('.stb_contribution_report_table_display').html(`
                            <table class="table table-striped" width="100%">
                                <thead>
                                    <tr class="bg-primary">
                                        <th style="font-size:12px;" class="text-white"> N0# </th>
                                        <th style="font-size:12px;" class="text-white"> Year </th>
                                        <th style="font-size:12px;" class="text-white"> Month </th>
                                        <th style="font-size:12px;" class="text-white"> Debit </th>
                                        <th style="font-size:12px;" class="text-white"> Credit </th>
                                        <th style="font-size:12px;" class="text-white"> Balance </th>
                                        <th style="font-size:12px;" class="text-white"> Uploaded Date </th>
                                    </tr>
                                </thead>
                                <tbody class="stb_contribution_report_table_body">
                                    ${result}
                                </tbody>
                            </table>
                        `)
                    } else {
                        lSelector('.stb_contribution_report_table_body').append(result)
                    }
                } else if (formData.type == 'interest share statement') {
                    result = this._shareInterestTableDraw(data.data, (formData.offset == 0 ? 'new' : 'append'), formData.offset)
                    if (formData.offset == 0) {
                        lSelector('.stb_contribution_report_table_display').html(`
                            <table class="table table-striped" width="100%">
                                <thead>
                                    <tr class="bg-primary">
                                        <th style="font-size:12px;" class="text-white"> N0# </th>
                                        <th style="font-size:12px;" class="text-white"> Year </th>
                                        <th style="font-size:12px;" class="text-white"> Balance </th>
                                        <th style="font-size:12px;" class="text-white"> Credit </th>
                                        <th style="font-size:12px;" class="text-white"> Uploaded Date </th>
                                    </tr>
                                </thead>
                                <tbody class="stb_contribution_report_table_body">
                                    ${result}
                                </tbody>
                            </table>
                        `)
                    } else {
                        lSelector('.stb_contribution_report_table_body').append(result)
                    }
                } else {
                    result = this._allTableDraw(data.data, (formData.offset == 0 ? 'new' : 'append'), formData.offset)
                    if (formData.offset == 0) {
                        // <th style="font-size:12px;" class="text-white"> Bal. B/FWRD </th>
                        lSelector('.stb_contribution_report_table_display').html(`
                            <table class="table table-striped" width="100%">
                                <thead>
                                    <tr class="bg-primary">
                                        <th style="font-size:12px;" class="text-white"> N0# </th>
                                        <th style="font-size:12px;" class="text-white"> Year </th>
                                        <th style="font-size:12px;" class="text-white"> Month </th>
                                        <th style="font-size:12px;" class="text-white"> Debit </th>
                                        <th style="font-size:12px;" class="text-white"> Credit </th>
                                        <th style="font-size:12px;" class="text-white"> Balance </th>
                                        <th style="font-size:12px;" class="text-white"> Description </th>
                                        <th style="font-size:12px;" class="text-white"> Uploaded Date </th>
                                    </tr>
                                </thead>
                                <tbody class="stb_contribution_report_table_body">
                                    ${result}
                                </tbody>
                            </table>
                        `)
                    } else {
                        lSelector('.stb_contribution_report_table_body').append(result)
                    }
                }
                lSelector('.stb_contribution_report_span_from').html(formData.date_from)
                lSelector('.stb_contribution_report_span_to').html(formData.date_to)
                lSelector('.stb_contribution_report_records').html(data.count)
                lSelector('.stb_contribution_report_grandtotal').html(this._GM.formatNumber(Number(data.total)))
                lSelector('.stb_contribution_report_card').removeClass('hide')
            }

            lSelector('.stb_contribution_report_submit_btn').html(`<i class="bi bi-clipboard-data mr-3"></i> Fetch`)
            lSelector('.ovasyte_report_offset_number_btn').html(`Get ${formData.limit} More Records <i class="far fa-arrow-alt-circle-right"></i>`);

            lSelector('.stb_contribution_report_submit_btn').removeAttr('disabled')
            lSelector('.ovasyte_report_offset_number_btn').removeAttr('disabled')
        }).catch((error) => {
            console.log('error: ', error)
            lSelector('.stb_contribution_report_submit_btn').html(`<i class="bi bi-clipboard-data mr-3"></i> Fetch`)
            lSelector('.ovasyte_report_offset_number_btn').html(`Get ${formData.limit} More Records <i class="far fa-arrow-alt-circle-right"></i>`);

            lSelector('.stb_contribution_report_submit_btn').removeAttr('disabled')
            lSelector('.ovasyte_report_offset_number_btn').removeAttr('disabled')
        }) 
    }
}

export default DashboardBody