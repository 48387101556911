// import dateFormat from "dateformat"


class GeneralMethods {

    public apiUrl() {
        return 'https://fcstb.org/api'
        // return 'http://localhost:5010/api'
    }

    public validate(item:any) {
        if (item === '' || item === undefined || item === null || item === ' ') {
            return ''
        } else {
            return item
        }
    }

    public isEmptyArray(arr:any[]):any[] {
        const empty = []
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (item === '' || item === undefined || item === null || item === ' ') {
                empty.push('empty')
            }
        }
        return empty
    }

    public ifEmptyObjectArray (columns:any[]):any[] {
        let checker:any[] = []
        if (columns.length > 0) {
            columns.forEach(item => {
                if (item === '' || item === undefined || item === null  || item.length < 1  || item.length === 0) {
                    checker.push('empty')
                }
            })
        } 

        return checker
    }

    public getTimeStamp ():number {
        let timestamp = Date.now();
        let time = timestamp.toString().substr((timestamp.toString().length - 8), 8);
        return Number(this.shuffle(time));
    }

    public getDateTime ():string {
        let date = new Date();
        let newdate = date.getUTCFullYear()+'-'+(date.getUTCMonth()+1)+'-'+date.getUTCDate()+' '+date.getUTCHours()+':'+date.getUTCMinutes()+':'+date.getUTCSeconds();
        // let todayDate = new Date(newdate);
        // let newTodayDate = dateFormat(todayDate, 'yyyy-mm-dd HH:MM:ss');
        return newdate;
    }

    public shuffle(value:string):string {
        let a = value.toString().split(""), 
        n = a.length;
        for (let i = n - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            let tmp = a[i];
            a[i] = a[j];
            a[j] = tmp;
        }
        return a.join("");
    }

    public formatNumber(num:number):string {
        return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }

    public getMonthString(month:string) {
        if (month.toString() == '01') {
            return 'January';
        } else if (month.toString() == '02') {
            return 'February';
        } else if (month.toString() == '03') {
            return 'March';
        } else if (month.toString() == '04') {
            return 'April';
        } else if (month.toString() == '05') {
            return 'May';
        } else if (month.toString() == '06') {
            return 'June';
        } else if (month.toString() == '07') {
            return 'July';
        } else if (month.toString() == '08') {
            return 'August';
        } else if (month.toString() == '09') {
            return 'September';
        } else if (month.toString() == '10') {
            return 'October';
        } else if (month.toString() == '11') {
            return 'November';
        } else {
            return 'December';
        }
    }
    
    public fullDate(date:string) {
        if (date == "" || date == null || date == undefined) {
            return ''
        } else {
            const newdate = new Date(date)
            return newdate.toDateString()
        }
    }

    public fullDateTime(date:string) {
        if (date === "" || date === null || date === undefined) {
            return '';
        } else {
            const newdate = new Date(date);
            let hh = newdate.getUTCHours();
            let min = newdate.getUTCMinutes();
            let sec = newdate.getUTCSeconds();
            return newdate.toDateString()+' '+hh+':'+min+':'+sec;
        }
    }

    public print(printMe:string) {
        let docHead = document.head.innerHTML
        let printArea:any = document.getElementById(printMe)?.innerHTML
        let newWindow:any = window.open('', '', 'height=768, width=1280')
        newWindow.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
        `);
        newWindow.document.write(docHead)
        newWindow.document.write(`
            </head>
            <body class="bg-white p-5 m-0">
        `);
        newWindow.document.write(printArea)
        newWindow.document.write(`
            <script>
                (()=>{
                    setTimeout(()=>{
                        window.print();
                    }, 1000);

                    window.onafterprint = function closeWindow() {
                        window.close();
                    }
                })();
            </script>
        `);
        newWindow.document.write(` </body> `)
        newWindow.document.write(`</html> `)
        newWindow.focus()
        newWindow.document.close()
    }
}

export default GeneralMethods