import lSelector from 'jquery'
import DashboardBody from './DashboardBody'
import Footer from './Footer'
import Navbar from './Navbar'

class Dashboard {
    private _AppBody:JQuery
    private _AppHtml:string

    //Main Pages import
    private _Navbar:Navbar
    private _Body:DashboardBody
    private _Footer:Footer
    

    constructor(App:string) {
        this._AppBody = lSelector(App)
        this._AppHtml = ''

        //Call Pages Here
        this._Navbar = new Navbar()
        this._Body = new DashboardBody()
        this._Footer = new Footer()

        //Render to add all html to page
        this._render()

        //Call methods to execute all actions and events
        this._Methods()
    }

    private _render() {
        this._AppHtml = `
            ${this._Navbar.render()}
            ${this._Body.render()}
            ${this._Footer.render()}
        `
        this._AppBody.html(this._AppHtml)
    }

    private _Methods() {
        this._Navbar.methods()
        this._Body.methods()
    }
}

export default Dashboard