

class Footer {


    constructor() {
        
    }

    public render() {
        return (`
            <footer class="border-top border-white-10" style="background-color: #f1f2f3;">
                <div class="container">
                    <div class="border-top" style="background-color: #ccc;"></div>
            
                    <div class="row align-items-md-end py-5">
                        <div class="col-md mb-3 mb-md-0">
                            <p class="mb-0" style="color: #03830A; font-family: 'Nunito', sans-serif;">2021 © Forestry Commission of Ghana</p>
                        </div>
            
                        <div class="col-md d-md-flex justify-content-md-end">
                            <a href="https://logig.tech" class="mb-0" style="color: #03830A; font-family: 'Nunito', sans-serif;">Powered By: <span style="color: #74743c;">LoGig</span></a>
                        </div>
                    </div>
                </div>
            </footer>
        `)
    }
}

export default Footer