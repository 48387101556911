import Footer from './src/components/Footer'
import IndexPage from './src/components/IndexPage'
import lSelector from 'jquery'
import Dashboard from './src/components/Dashboard'

class MyStb {
    private _AppBody:JQuery
    private _AppHtml:string

    //Main Pages import
    private _IndexPage:IndexPage
    private _Footer:Footer

    constructor(App:string) {
        this._AppBody = lSelector(App)
        this._AppHtml = ''

        //Call Pages Here
        this._IndexPage = new IndexPage()
        this._Footer = new Footer()

        //Render to add all html to page
        this._render()

        //Call methods to execute all actions and events
        this._Methods()
    }

    private _render() {
        this._AppHtml = `
            ${this._IndexPage.render()}
            ${this._Footer.render()}
        `
        this._AppBody.html(this._AppHtml)
    }

    private _Methods() {
        this._IndexPage.methods()
    }
}

window.onload = () => {
    const State = window.localStorage.getItem('mystbstate')
    if (State && State != undefined) {
        new Dashboard('#myStbMain')
    } else {
        new MyStb('#myStbMain')
    }
}