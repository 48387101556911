import axios from 'axios'
import lSelector from 'jquery'
import Swal from 'sweetalert2'
import * as Types from '../modules/ModuleType'
import GeneralMethods from '../modules/GeneralMethods'
const Logo = require('../../assets/img/logo.gif')

class Navbar {
    private _NavbarState:Types.dashboardState
    private _GM:GeneralMethods

    constructor() {
        this._NavbarState = {
            employee_number: '',
            full_name: '',
            phone: '',
            email: '',
            dob: '',
            unit: '',
            district: '',
            region: '',
            session_code: 0
        }
        this._GM = new GeneralMethods()

        this._setStateData()
    }

    public render() {
        return (`
            <header id="header" class="navbar navbar-expand-lg navbar-light navbar-right bg-white">
                <div class="container">
                    <nav class="js-mega-menu navbar-nav-wrap">
                        <a class="navbar-brand" href="./index.html" aria-label="Space">
                            <img class="navbar-brand-logo" src="${Logo}" alt="Logo" style="min-width:50%; max-width:70%; height:auto;">
                        </a>
            
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-default"> <i class="bi-list"></i> </span>
                            <span class="navbar-toggler-toggled"> <i class="bi-x"></i> </span>
                        </button>
                    
                        <div class="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="js-animation-link d-lg-none btn btn-primary stbLogoutBtn" href="javascript:;" role="button">
                                        <i class="bi bi-lock-fill me-1"></i> Logout
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="js-animation-link d-none d-lg-inline-block btn btn-primary stbLogoutBtn" href="javascript:;" role="button">
                                        <i class="bi bi-lock-fill me-1"></i> Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        `)
    }

    public methods() {
        lSelector(document).off('click.stbLogoutBtn')
        this._Logout()
    }

    private _Logout() {
        const thisClass = this
        lSelector(document).on('click.stbLogoutBtn', 'a.stbLogoutBtn', function(e) {
            e.preventDefault()
            try {
                lSelector('.stbLogoutBtn').html(`<div class="spinner-border text-light" role="status"> <span class="visually-hidden">Loading...</span> </div>`)
                lSelector('.stbLogoutBtn').attr('disabled', 'disabled')

                axios({
                    method: 'POST',
                    url: thisClass._GM.apiUrl()+'/logout',
                    data: {
                        token: '9a741c670d094ba6cf040e12b525bcd7',
                        employee_number: thisClass._NavbarState.employee_number,
                        session_code: thisClass._NavbarState.session_code
                    }
                }).then((res) => {
                    const data:Types.loginResponse = res.data

                    if (data.status == 'success') {
                        window.localStorage.removeItem('mystbstate')
                        window.location.reload()
                    } else {
                        Swal.fire(
                            data.status == 'success' ? 'Success' : (data.status == 'caution' ? 'Caution' : 'Error'),
                            data.message,
                            data.status == 'success' ? 'success' : (data.status == 'caution' ? 'warning' : 'error')
                        )
                        lSelector('.swal2-confirm').removeClass('swal2-styled')
                        lSelector('.swal2-confirm').addClass('btn btn-primary')
                    }

                    lSelector('.stbLogoutBtn').html(`<i class="bi bi-lock-fill me-1"></i> Logout`)
                    lSelector('.stbLogoutBtn').removeAttr('disabled')
                }).catch((error) => {
                    console.log('error: ', error)
                    lSelector('.stbLogoutBtn').html(`<i class="bi bi-lock-fill me-1"></i> Logout`)
                    lSelector('.stbLogoutBtn').removeAttr('disabled')
                }) 
            } catch (error) {
                console.log('error: ', error)
                lSelector('.stbLogoutBtn').html(`<i class="bi bi-lock-fill me-1"></i> Logout`)
                lSelector('.stbLogoutBtn').removeAttr('disabled')
            }
        })
    }

    private _setStateData() {
        const data = window.localStorage.getItem('mystbstate')
        if (data && data != undefined) {
            this._NavbarState = JSON.parse(data)
        }
    }
}

export default Navbar